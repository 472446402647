import React, { useEffect, useState } from "react";
import { Data, projectNav } from "../constants";
import WorksItem from "./WorkItem";
import "../Items/team.css";

const TeamWork = () => {
  const [item, setItem] = useState({ name: "ALL" });
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (item.name === "ALL") {
      setProjects(Data);
    } else {
      const newProjects = Data.filter((project) => {
        return project.category === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e) => {
    setItem({ name: e.target.textContent });
  };

  return (
    <div className="py-12">
      <div className="work__filter ">
        {projectNav.map((navItem, index) => {
          return (
            <span
              onClick={handleClick}
              className={`work__item shadow-red-glow ${
                item.name === navItem.name ? "active" : ""
              }`}
              key={index}
            >
              {navItem.name}
            </span>
          );
        })}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {projects.map((project) => {
          return <WorksItem item={project} key={project.id} />;
        })}
      </div>
    </div>
  );
};

export default TeamWork;
