import React, { useEffect } from "react";
import HeaderWithBackground from "../components/HeaderWithBackground";
import img1 from "../assets/img1.jpg";
import NewsDatail from "../components/NewsDatail";
import BgImg from "../components/BgImg";

const NewsMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-24 container mx-auto text-white">
      <HeaderWithBackground backgroundImage={img1} title="NEWS" />
      <BgImg>
        <NewsDatail />
      </BgImg>
    </div>
  );
};

export default NewsMain;
