import React from "react";
import HeroSection from "./HeroSection";
import About from "./About";
import NewsCard from "./NewsCard";

import LogoTicker from "../components/LogoTicker";
import TeamCard from "../components/TeamCard";
import BgImg from "../components/BgImg";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <LogoTicker />
      <BgImg>
        <About />
        <NewsCard />
      </BgImg>
    </div>
  );
};

export default Home;
