import React, { useState, useEffect } from "react";
import { FaXTwitter, FaYoutube } from "react-icons/fa6";
import YouTube from "react-youtube";
import { FaTwitch } from "react-icons/fa";

const WorkItem = ({ item }) => {
  const [modal, setModal] = useState(false);
  const [videoSize, setVideoSize] = useState({ width: "100%", height: "390" });

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 640) {
        setVideoSize({ width: "100%", height: "200" });
      } else if (window.innerWidth <= 768) {
        setVideoSize({ width: "100%", height: "240" });
      } else if (window.innerWidth <= 1024) {
        setVideoSize({ width: "100%", height: "315" });
      } else {
        setVideoSize({ width: "640", height: "390" });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modal]);

  const opts = {
    height: videoSize.height,
    width: videoSize.width,
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div className="p-4">
      <div className="bg-[#141414] rounded-lg border-2 border-orange-500 overflow-hidden transform transition duration-300 hover:scale-105 max-w-sm mx-auto">
        <div className="aspect-w-16 aspect-h-9">
          <img
            src={item.image}
            alt={item.title}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="p-4">
          <h3 className="work__title text-4xl font-bold text-orange-500 mb-2 truncate">
            {item.title}
          </h3>
          <p className="text-white text-xl mb-4">{item.subtitle}</p>

          <button className="group relative inline-flex h-10 items-center justify-center overflow-hidden rounded-md border border-neutral-200 bg-white font-medium">
            <div className="inline-flex h-12 translate-y-0 items-center justify-center px-6 text-neutral-950 transition duration-500 group-hover:-translate-y-[150%]">
              選手詳細へ
            </div>
            <div
              onClick={toggleModal}
              className="absolute inline-flex h-12 w-full translate-y-[100%] items-center justify-center text-neutral-50 transition duration-500 group-hover:translate-y-0"
            >
              <span className="absolute h-full w-full translate-y-full skew-y-12 scale-y-0 bg-orange-500 transition duration-500 group-hover:translate-y-0 group-hover:scale-150"></span>
              <span className="z-10">PLAYERDETAIL</span>
            </div>
          </button>
        </div>
      </div>

      {modal && (
        <div
          className="modal-overlay fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={toggleModal}
        >
          <div
            className="modal-content bg-[#141414] rounded-lg shadow-red-glow w-full max-w-5xl mx-4 p-4 overflow-y-auto max-h-[90vh]"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="border-b border-neutral-900 pb-4 ">
              <div className="flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 p-4">
                  <div className="flex flex-col items-center lg:items-start">
                    <h1 className="text-4xl lg:text-6xl tracking-tight mb-4">
                      {item.title}
                    </h1>
                    <span className="bg-gradient-to-r font-semibold from-pink-300 via-slate-500 to-purple-500 bg-clip-text text-2xl lg:text-4xl tracking-tight text-transparent mb-4">
                      {item.subtitle}
                    </span>
                    <p className="text-white tracking-tighter mb-2">
                      {item.description}
                    </p>
                    <p className="text-white tracking-tighter mb-2">
                      {item.description1}
                    </p>
                    <p className="text-white tracking-tighter mb-2">
                      {item.description2}
                    </p>
                    <div>
                      <h3 className="text-2xl py-4 font-semibold">主な戦績</h3>
                      <p className="pb-2">{item.record}</p>
                      <p className="pb-2">{item.record1}</p>
                      <p className="pb-2">{item.record2}</p>
                      <p className="pb-2">{item.record3}</p>
                      <p className="pb-2">{item.record4}</p>
                      <p className="pb-2">{item.record5}</p>
                      <p className="pb-2">{item.record6}</p>
                      <p className="pb-2">{item.record7}</p>
                      <p className="pb-2">{item.record8}</p>
                      <p className="pb-2">{item.record9}</p>
                    </div>
                    <div className="modal-social-icons py-8">
                      <a
                        href={item.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-4"
                      >
                        <FaXTwitter className="text-blue-700 text-3xl " />
                      </a>
                      <a
                        href={item.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-4"
                      >
                        <FaYoutube className="text-red-600 text-3xl " />
                      </a>
                      <a
                        href={item.twitch}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitch className="text-purple-600 text-3xl " />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-4">
                  <div className="flex justify-center items-center h-full">
                    <YouTube videoId={item.youtubeId} opts={opts} />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="close-modal absolute top-4 right-4 text-white text-2xl"
              onClick={toggleModal}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkItem;
