import React from "react";
import { motion } from "framer-motion";

const LogoTicker = () => {
  const sponsors = [
    { text1: "スポンサー様募集中" },
    { text2: "#OSG_WIN" },
    { text3: "スポンサー様募集中" },
    { text4: "#OSG_WIN" },
    { text1: "スポンサー様募集中" },
    { text2: "#OSG_WIN" },
    { text3: "スポンサー様募集中" },
    { text4: "#OSG_WIN" },
    { text2: "スポンサー様募集中" },
    { text3: "#OSG_WIN" },
    { text4: "スポンサー様募集中" },
  ];

  return (
    <section className="py-20 md:py-12 container mx-auto">
      <div className="text-white">
        <div className="flex items-center gap-5">
          <div className="flex-1 md:flex-none">
            <h2 className="text-xl md:text-2xl font-semibold">Sponsored By</h2>
          </div>
          <div className="flex flex-1 overflow-hidden relative">
            <motion.div
              initial={{ translateX: "-50%" }}
              animate={{ translateX: "0%" }}
              transition={{
                repeat: Infinity,
                duration: 30,
                ease: "linear",
              }}
              className="flex flex-none gap-14 pr-14 -translate-x-1/2 text-xl"
            >
              {sponsors.map((sponsor, index) => (
                <div key={index}>{Object.values(sponsor)}</div>
              ))}
            </motion.div>
            <div className="absolute inset-0 bg-gradient-to-l from-transparent via-[rgba(0,0,0,0.28)] to-[rgba(0,0,0,0.88)] from-transparent"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoTicker;
