import React from "react";
import Teams from "../components/Teams";

const Member = () => {
  return (
    <div className="text-white  pt-24">
      <Teams />
    </div>
  );
};

export default Member;
