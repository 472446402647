import React, { useEffect } from "react";
import HeaderWithBackground from "../components/HeaderWithBackground";
import img1 from "../assets/img1.jpg";
import FormMain from "../components/FormMain";
import BgImg from "../components/BgImg";

const Form = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="container mx-auto text-white pt-24">
      <BgImg>
        <HeaderWithBackground backgroundImage={img1} title="CONTACT" />
        <FormMain />
      </BgImg>
    </section>
  );
};

export default Form;
