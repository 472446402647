import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import herovideo from "../assets/herovideo.mp4";
import logo1 from "../assets/logo.png";

const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.playsInline = true;
      videoRef.current.play().catch((error) => {
        console.log("Auto-play was prevented:", error);
      });
    }
  }, []);

  return (
    <div>
      <div
        data-scroll
        data-scroll-speed="-0.8"
        className="relative flex min-h-screen items-end justify-center"
        id="hero"
      >
        <video
          ref={videoRef}
          src={herovideo}
          autoPlay
          muted
          playsInline
          className="absolute inset-0 z-10 h-full w-full object-cover"
        />
        <motion.div
          className="absolute inset-0 z-10 bg-gradient-to-b
          from-60% to-black sm:from-50% md:from-40% lg:from-30%"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        ></motion.div>
        <motion.div
          className="z-20 mx-4 max-w-3xl pb-10 sm:pb-16 md:pb-20 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 1 }}
        >
          <img className="mx-auto w-3/4 sm:w-auto" src={logo1} alt="ロゴ" />
          <p className="pt-2 font-semibold text-orange-500 text-2xl sm:text-3xl md:text-4xl">
            ゲームをもっと身近に
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroSection;
