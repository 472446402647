import React, { useState, useEffect } from "react";
import "animate.css";

const HeaderWithBackground = ({ backgroundImage, title }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      <div
        className="absolute inset-0"
        style={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 0.7) 100%)`,
        }}
      ></div>
      <div className="relative z-10 py-24">
        <h2
          className={`text-center text-4xl md:text-7xl font-bold text-orange-500 
                animate__animated ${
                  isVisible ? "animate__zoomInDown" : "invisible"
                }`}
          style={{ "--animate-duration": "1s" }}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};

export default HeaderWithBackground;
