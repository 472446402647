import React from "react";

const Card = ({ image, date, title, link }) => {
  return (
    <a href={link} className="block w-full">
      <div className="bg-[#141414] w-full sm:w-[350px] md:w-[400px] lg:w-[450px] rounded-lg  border-2 border-orange-500 overflow-hidden transform transition duration-300 hover:scale-105 mx-auto">
        <div className="relative h-[200px] sm:h-[225px] md:h-[250px] w-full overflow-hidden">
          <img
            src={image}
            alt={title}
            className=" w-full h-full inset-0 absolute"
          />
        </div>
        <div className="p-3 sm:p-4">
          <h3 className="text-lg sm:text-xl font-bold text-white mb-1 sm:mb-2 truncate">
            {date}
          </h3>
          <p className="text-orange-500 truncate text-xl sm:text-2xl font-semibold">
            {title}
          </p>
        </div>
      </div>
    </a>
  );
};

export default Card;
