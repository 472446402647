import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div
      className="container mx-auto px-4 lg:px-0 text-white overflow-x-hidden"
      id="about"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="mt-12 lg:mt-20 text-center text-3xl lg:text-4xl font-semibold text-white"
      ></motion.h2>
      <motion.h3
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="p-4 text-xl lg:text-6xl uppercase text-center lg:text-left lg:text-[4.8rem]"
      >
        <div className="mb-2 lg:mb-0">選手たちがゲームで輝くとき、</div>
        <div>応援の声がゲームをもっとおもしろくする</div>
      </motion.h3>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="lg:mr-24 pl-4 text-base lg:text-3xl my-12 lg:my-24 font-normal leading-relaxed lg:leading-loose"
      >
        <div className="mb-4 lg:mb-0">
          Our'ｓShipGamingを運営するアワーズシップ株式会社は
          <span className="text-orange-500 font-bold">
            SES(システムエンジニアリングサービス)事業を主力
          </span>
          として活動している企業です。
        </div>
        <div>
          過去にe-sportsを真面目に取り組んだメンバーたちが集まり、 またあの
          <span className="text-orange-500 font-bold">
            熱意とそれぞれの想いがぶつかり合う場に関わりたい
          </span>
          と思い Our'ｓShipGamingを設立しました。
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="lg:mr-24 pl-4 text-base lg:text-3xl my-12 lg:my-24 font-normal leading-relaxed lg:leading-loose"
      >
        <div className="mb-4 lg:mb-0">
          アワーズシップ株式会社では、
          <span className="text-orange-500 font-bold">
            SESに関する求人採用も積極的に行っています。
          </span>
        </div>
        <div className="mb-4 lg:mb-0">
          弊社に興味が沸いた方、組織拡大に協力したいと思っていただけた方、ぜひ一緒に働きませんか。
        </div>
        <div>
          履歴書等不要で
          <span className="text-orange-500 font-bold">
            何の準備も必要のないカジュアル面談も行ってます
          </span>
          のでお気軽にお問合せください。
        </div>
      </motion.div>
    </div>
  );
};

export default About;
