import React from "react";
import { useEffect } from "react";

const FormMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="mb-8 xs:mb-16 md:mb-32 p-4 xs:p-8 md:p-12"></div>
      <div className="flex items-center justify-center min-h-screen px-4 xs:px-6 md:px-8">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSc4uVcBEiHEjOupsKtOGK1guumpOMulgf3XPsULuvnZD-bT-Q/viewform?embedded=true"
          width="100%"
          height="600"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Google Form"
          className="w-full max-w-xs xs:max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl h-[500px] xs:h-[600px] sm:h-[700px] md:h-[800px] lg:h-[900px] xl:h-[1000px]"
        >
          読み込んでいます…
        </iframe>
      </div>
    </div>
  );
};

export default FormMain;
