import React, { useEffect } from "react";
import HeaderWithBackground from "../components/HeaderWithBackground";
import img1 from "../assets/img1.jpg";
import History from "../components/History";
import Results from "../components/Results";
import BgImg from "../components/BgImg";

const MainHistory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto text-white pt-24">
      <BgImg>
        <HeaderWithBackground backgroundImage={img1} title="HISTORY" />

        <History />
        <Results />
      </BgImg>
    </div>
  );
};

export default MainHistory;
