import React, { useState } from "react";
import jump from "../assets/jump.png";
import { SOCIAL_MEDIA_LINKS } from "../constants";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`mt-20 text-white bg-[#141414] relative border-t-4 ${
        isHovered ? "border-red-600" : "border-orange-500"
      } transition-colors duration-300`}
    >
      <div
        className="absolute left-1/2 transform -translate-x-1/2 -top-16 transition-all duration-300 ease-in-out hover:-translate-y-2 hover:scale-105"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <figure onClick={handleScrollToTop}>
          <img
            src={jump}
            alt="Jump to top"
            width={120}
            className="mb-2 cursor-pointer"
          />
        </figure>
      </div>
      <div className="pt-20 px-4 md:px-10">
        <div className="flex items-center justify-center gap-6 md:gap-8 my-8">
          {SOCIAL_MEDIA_LINKS.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl md:text-3xl"
            >
              {link.icon}
            </a>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4 md:gap-8 my-8">
          <Link to="/" onClick={handleScrollToTop}>
            <img
              src={logo}
              alt="logo"
              className="w-[150px] md:w-[200px] h-auto cursor-pointer"
            />
          </Link>
          <Link
            to="/about"
            className="text-white hover:text-orange-500 cursor-pointer duration-300"
          >
            ABOUT
          </Link>
          <Link
            to="/news"
            className="text-white hover:text-orange-500 cursor-pointer duration-300"
          >
            NEWS
          </Link>
          <Link
            to="/member"
            className="text-white hover:text-orange-500 cursor-pointer duration-300"
          >
            TEAM
          </Link>
          <Link
            to="/history"
            className="text-white hover:text-orange-500 cursor-pointer duration-300"
          >
            HISTORY
          </Link>
          <a
            href="https://oursship.co.jp/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-orange-500 cursor-pointer duration-300 font-bold"
          >
            SES
          </a>
          <Link
            to="/form"
            className="text-white hover:text-orange-500 cursor-pointer duration-300"
          >
            CONTACT
          </Link>
        </div>
        <div className="footer-copyright w-full">
          <hr className="border-gray-600" />
          <p className="text-center text-gray-400 text-sm md:text-base py-4">
            Copyright © 2024 Our's Ship All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
