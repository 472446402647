import React from "react";
import { history } from "../constants";

const Results = () => {
  return (
    <div className="container w-full px-4 sm:px-0 sm:w-3/4 mx-auto text-white">
      <h2 className="mb-8 sm:mb-12 mt-12 sm:mt-20 text-center text-3xl sm:text-4xl font-bold">
        OURSSHIP HISTORY
      </h2>
      <div className="mx-2 flex flex-col rounded-3xl px-4 py-2 sm:px-20 border border-orange-500">
        {history.map((his, index) => (
          <div
            key={index}
            className={`py-4 sm:py-6 flex flex-col sm:flex-row sm:items-center sm:justify-between ${
              index === history.length - 1
                ? "border-b-0"
                : "border-b border-orange-500"
            }`}
          >
            <div className="flex flex-col sm:flex-row sm:items-center">
              <h3 className="text-lg sm:text-2xl font-semibold mb-2 sm:mb-0 sm:px-6 text-orange-500">
                {his.date}
              </h3>
              <p className="text-base sm:text-lg">{his.experience}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Results;
