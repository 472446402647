import React from "react";
import { PROJECTS } from "../constants";
import Card from "../components/Card";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.4,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
};

const News = () => {
  return (
    <div id="news" className="container mx-auto px-4 py-24">
      <motion.h2
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true }}
        className="mb-12 text-center text-4xl md:text-6xl font-bold text-orange-500"
      >
        NEWS
      </motion.h2>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true }}
        className="flex flex-wrap justify-center gap-8"
      >
        {PROJECTS.map((project, index) => (
          <motion.div key={index} variants={itemVariants}>
            <Card
              image={project.image}
              date={project.date}
              title={project.title}
              link={project.link || "#"}
            />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default News;
