import React, { useEffect } from "react";
import img1 from "../assets/img1.jpg";
import HeaderWithBackground from "./HeaderWithBackground";
import AboutMain1 from "./AboutMain1";
import OursShip from "./OursShip";
import BgImg from "./BgImg";

const AboutHead = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto text-white">
      <HeaderWithBackground backgroundImage={img1} title="ABOUT" />
      <BgImg>
        <AboutMain1 />
      </BgImg>
      <HeaderWithBackground backgroundImage={img1} title="会社概要" />
      <OursShip />
    </div>
  );
};

export default AboutHead;
