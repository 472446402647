import React from "react";

const NewsDatail = () => {
  return (
    <section className="pt-20">
      <h2 className="text-center text-4xl font-semibold tracking-tighter">
        お知らせ
      </h2>
      <div className="space-y-8 p-10">
        <div className="rounded-xl border border-orange-500 bg-white/10 p-4">
          <p className="text-sm text-gray-100">09.01.2024</p>
          <h3 className="text-2xl font-bold text-orange-500 ">
            Our’ｓShipGaming設立のお知らせ
          </h3>

          <p className="mt-2 text-base">
            この度、アワーズシップ株式会社はプロesportsチームとして活動していくOur’ｓShipGamingを設立したことをご報告いたします。
            アワーズシップ株式会社はSES(システムエンジニアリングサービス)事業を主力として活動している企業です。
            過去にe-sportsを真面目に取り組んだメンバーたちが集まり、
            またあの熱意とそれぞれの想いがぶつかり合う場に関わりたいと思い
            Our’ｓShipGamingを設立しました。
          </p>
          <p className="mt-2 text-base">
            アワーズシップ株式会社では、SESに関する求人採用も積極的に行っています。
            弊社に興味が沸いた方、組織拡大に協力したいと思っていただけた方、ぜひ一緒に働きませんか。
            履歴書等不要で何の準備も必要のないカジュアル面談も行ってますのでお気軽にお問合せください。
          </p>
        </div>
      </div>
      <div className="space-y-8 p-10">
        <div className="rounded-xl border border-orange-500 bg-white/10 p-4">
          <p className="text-sm text-gray-100">09.01.2024</p>
          <h3 className="text-2xl font-bold text-orange-500 ">
            ApexLegends部門設立のお知らせ
          </h3>

          <p className="mt-2 text-base">
            APEX LEGENDS部門も同時に設立し、Matsu
            Tash、Nyanmerry、ShizuAriesの3名が加入します。 9月6、7日にはApex
            Legends Global Series: 2024 Last Chance
            Qualifier(LCQ)に出場予定となっております。
            選手それぞれが全力でChampionShip出場に向けて邁進してまいりますのでぜひ応援をお願いいたします。
          </p>
        </div>
      </div>
      <div className="space-y-8 p-10">
        <div className="rounded-xl border border-orange-500 bg-white/10 p-4">
          <p className="text-sm text-gray-100">09.01.2024</p>
          <h3 className="text-2xl font-bold text-orange-500 ">
            社内イベントについて
          </h3>

          <p className="mt-2 text-base">
            所属選手と社員間のコミュニケーション施策として、プロ選手と社員のゲームでの対戦イベント、対面でのコーチングイベント等の将来的な開催を考えています。
          </p>
        </div>
      </div>
    </section>
  );
};

export default NewsDatail;
