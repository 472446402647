import React from "react";
import AboutHead from "../components/AboutHead";
import BgImg from "../components/BgImg";

const MainAbout = () => {
  return (
    <div className="text-white  pt-24">
      <BgImg>
        <AboutHead />
      </BgImg>
    </div>
  );
};

export default MainAbout;
