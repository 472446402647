import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./pages/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Member from "./pages/Member";
import MainAbout from "./pages/MainAbout";
import Form from "./pages/Form";
import MainHistoy from "./pages/MainHistory";
import NewsMain from "./pages/NewsMain";
import SocialIcons from "./components/SocialIcons";

function App() {
  return (
    <main className=" bg-black">
      <BrowserRouter>
        <Navbar />
        <SocialIcons />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/member" element={<Member />} />
          <Route path="/about" element={<MainAbout />} />
          <Route path="/history" element={<MainHistoy />} />
          <Route path="/news" element={<NewsMain />} />
          <Route path="/form" element={<Form />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </main>
  );
}

export default App;

{
  /* <Navbar />
      <HeroSection />
      <Marquee />
      <About />
      <News />
      <Footer /> */
}
